import React, {useState, useEffect} from 'react';

import { useAuth } from 'src/context/AuthContextProvider';
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import {getAccessLevel, checkIfPortalAdmin} from "src/checkPageAccess"
import { useSelector, useDispatch } from 'react-redux'
import {ADD_APP_ACCESS_LEVELS, ADMIN_ACCESS_LEVELS} from 'src/constants/AppConstants'
import { TEMPLATES } from 'src/Ateam-BI/Templates/Constants';

export default (props) => {
  const userAuthenticationDetails = useAuth();
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const history = useHistory();
  const userId  = userAuthenticationDetails.Alias;
  const currentStage = useSelector((globalState)=>globalState.auth.currentStage)
  
  const getBasicNavItems = () => {
    return [
      {
          "type": "link",
          "text": (<div>All Apps</div>),
          "href": "/"
      },

      {
          "type": "link",
          "text": (<div>Favorites</div>),
          "href": "/Favorites"
      },

      {
        "type": "link",
        "text": (<div>Approvals Home</div>),
        "href": "/ApprovalsHome"
      },
      {
        "type": "link",
        "text": (<div>All Audit Logs</div>),
        "href": "/AllAuditLogs"
      }
      // {
      //     "type": "link",
      //     "text": (<div>General Apps & Tools</div>),
      //     "href": "/GeneralTools/JournalPostings"
      // }  

  ]
  }

  const [items, setItems] = useState([
    {
        "type": "section",
        "text": "Applications",
        "defaultExpanded": true,
        "items": getBasicNavItems()

    }


    ]);


  useEffect(() => {
    showRestrictedOptions()   
  }, [])

  const showRestrictedOptions = async () => {
    let navItems = getBasicNavItems()
    let access = await getAccessLevel(userId,TEMPLATES.MAPLE,currentStage)
    let hasAdminAccess = await checkIfPortalAdmin(userId,currentStage)
    if(hasAdminAccess){
      let item = {
        "type": "link",
        "text": (<div>Admin panel</div>),
        "href": "/Admin"
      }
      navItems.push(item)
    }
    // console.log(items)
    items[0]['items'] = navItems
  }


  return (
    <SideNavigation
      activeHref={activeHref}
      onFollow={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
    }}
      items={items}
    />
  );
}