import React, { useState, useEffect } from "react";
import { TEMPLATE_APP, QSC_APP_SPECIFIC_FIELDS, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION } from "./Constants";
import CustomSelect from "./components/CustomSelect";
import CustomRadioGroup from "./components/CustomRadioGroup";
import CustomSelectAlias from "./components/CustomSelectAlias";
import CustomSelectMultiAlias from "./components/CustomSelectMultiAlias";
import CustomMultiHybridInputsWithPK from "./components/CustomMultiHybridInputsWithPK";


import CustomMultiSelect from "./components/CustomMultiSelect";
import CustomInput from "./components/CustomInput";
import CustomMultiInput from "./components/CustomMultiInput";
import {ADD_APP_ACCESS_LEVELS} from 'src/constants/AppConstants'
import { TEMPLATES } from 'src/Ateam-BI/Templates/Constants';
import { getEditableFields } from "./Utils/FormMetadata";
import { getSuggestedLogins } from "./Utils/AppActivityUtils";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoadingSpinner } from '../../generic-components/LoadingSpinner';
import {getAccessLevel} from "src/checkPageAccess"
import ErrorComponent from "./components/ErrorComponent";
import { addQSCApp, createAuditLog } from "./Utils/AppActivityUtils"




// import Wizard from "@amzn/awsui-components-react/polaris/wizard";

import {
    AppLayout,
    BreadcrumbGroup,
    Container,
    Box,
    Cards,
    Button,
    Header,
    Table,
    Badge,
    Autosuggest,
    ExpandableSection,
    SpaceBetween,
    Multiselect,
    ColumnLayout,
    Tabs,
    Pagination,
    Spinner,
    SegmentedControl,
    Select,
    Link,
    Wizard,
    FormField,
    Input,
    KeyValuePairs,
    Checkbox,
    Textarea,
    Grid,
    RadioGroup
} from "@amzn/awsui-components-react";
import SideNav from "./SideNav";

export default (props) => {
    const [NavOpen, setNavOpen] = useState(true);
    const history = useHistory();
    const [renderJsonEdited, setRenderJsonEdited] = useState({status: 'WIP'})
    const [fieldMetadata, setFieldMetadata] = useState({});
    const [errorText, setErrorText] = useState()
    const [customSideNavitems, setCustomSideNavitems] = useState([
        {
            "type": "link",
            "text": "About",
            "href": `/QSCApp`
        },

        { "type": "divider" },

        {
            "type": "link",
            "text": "Back",
            "href": "/"
        },
    ])
    const [suggestedLogins, setSuggestedLogins] = useState([])
    const userId = useSelector((globalState) => globalState.auth.userId);
    const currentStage = useSelector((globalState)=>globalState.auth.currentStage)
    const [qscAddAppAccess, setQscAddAppAccess] = useState()
    const [showLoadingIcon, setShowLoadingIcon] = useState(false);
    const [aliasesLoading, setAliasesLoading] = useState(false)



    useEffect(() => {
        checkAddQSCAppAccesses();
        loadEditableFields();
    }, [])

    useEffect(()=>{
        checkAddQSCAppAccesses()
      },[userId, currentStage])

    const checkAddQSCAppAccesses = async() => {
        if(userId && currentStage) {
          let qscAccess = await getAccessLevel(userId,TEMPLATES.QSC,currentStage)
          setQscAddAppAccess(ADD_APP_ACCESS_LEVELS.includes(qscAccess?.appAccessLevel))
        }
    
      }

    const getMultiInputFieldValues = (fieldName) => {
        if (renderJsonEdited && renderJsonEdited[fieldName]) {
            return renderJsonEdited[fieldName]
        } else {
            return []
        }
    }
    const getSelectFieldValues = (fieldName, subKey = null) => {
        if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
            if (fieldName === TEMPLATE_APP.STATUS) {
                fieldMetadata[fieldName]['values'].forEach((item) => {
                    if (item.value === 'Active' || item.value === 'Deprecated') {
                        item["disabled"] = true
                        item["disabledReason"] = '"Active" and "Deprecated" statuses are disabled when creating an application, please select "WIP".'
                    }
                })
            }
            return fieldMetadata[fieldName]['values']
        } else if (subKey && fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
            return fieldMetadata[fieldName][subKey]['values']
        } else {
            return []
        }
    }


    const getRadioYesOrNoOptions = () => {
        return [
            { value: true, label: "Yes" },
            { value: false, label: "No" }
        ]
    }

    const loadEditableFields = async () => {
        let res = await getEditableFields()
        setFieldMetadata(res)
    }

    const updateValue = (fieldName, value, subKey = null) => {
        if (subKey) {
            if (!renderJsonEdited.hasOwnProperty(fieldName)) {
                renderJsonEdited[fieldName] = {}
            }
            renderJsonEdited[fieldName][subKey] = value
        } else {
            renderJsonEdited[fieldName] = value
        }

        if(fieldName === QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE) {
            if(value === 'FAST'){
              updateValue(QSC_APP_SPECIFIC_FIELDS.FDV_DATASET_URL, '')
              updateValue(QSC_APP_SPECIFIC_FIELDS.FDV_QUERY_NAME, '')
            }  
          }

        if (fieldName === QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD ||
            fieldName === QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING || 
            fieldName === QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC) {
             
                if (!((renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD) &&
                    renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD] === true)
                    && (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING) &&
                        renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING] === true)
                    && (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC) &&
                        renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC] === true))) {
                            updateValue(QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET,'')
                }
            }
        setRenderJsonEdited(JSON.parse(JSON.stringify(renderJsonEdited)))
    }

    const getValue = (fieldName, subKey = null) => {
        if (subKey) {
            if (renderJsonEdited.hasOwnProperty(fieldName)) {
                return renderJsonEdited[fieldName][subKey]
            } else {
                return null
            }
        } else {
            return renderJsonEdited ? renderJsonEdited[fieldName] : null
        }
    }

    const getMultiSelectedOptions = (fieldName) => {
        let arr = renderJsonEdited[fieldName]
        let selectedOptions = []
        if (arr) {
            arr.forEach((item) => {
                selectedOptions.push({ label: item, value: item })
            })
        }
        return selectedOptions
    }


    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);

    const postProcessTeamEmails = (value) => {
        return value.replaceAll("@amazon.com", "")
    }

    const getSuggestedLoginsCaller = async (fragment, size = 10) => {
        setSuggestedLogins([])
        setAliasesLoading(true)
        const response = await getSuggestedLogins(fragment, size)
        const data = JSON.parse(response.data.getSuggestedLogins)
        let suggestions = data["suggestions"]
        suggestions = suggestions.map((login) =>
        ({
            label: login?.toLowerCase(),
            value: login?.toLowerCase()
        }))
        setSuggestedLogins(suggestions)
        setAliasesLoading(false)
        return suggestions
    }

    const getTeamObjectStringFormat = (teamObj) => {

        const result = []
        

        if (teamObj.hasOwnProperty('team')) {
            result.push(`Team: ${teamObj['team']}`)
        } else {
            result.push(`Team: -`)
        }

        if (teamObj.hasOwnProperty('email')) {
            result.push(`Email: ${teamObj['email']}`)
        } else {
            result.push(`Email: -`)
        }

        return result.join(', ')
        
    }

    const getYesOrNoBasedOnBoolean = (booleanValue) => {

        if(booleanValue === true) {
            return "Yes"
        } else if(booleanValue === false) {
            return "No"
        } else {
            return "-"
        }
    }

    const getCurrentTime = () => {
        return Date.now();
    }

    const onWizardNavigate = (detail) => {
        let checkFailed = false
        if (detail?.reason === 'next') {
            if (detail?.requestedStepIndex === 1) {
                const fieldsToCheck = [TEMPLATE_APP.APP_NAME, TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.STATUS, TEMPLATE_APP.FINANCIAL_YEAR_IMPACT]
                const listFieldsToCheck = [TEMPLATE_APP.FINANCIAL_RELEVANCE, TEMPLATE_APP.LEDGER_INFO, TEMPLATE_APP.CADENCE, TEMPLATE_APP.ORGANIZATION]
                const booleanFieldsToCheck = []
                const teamObjectsToCheck = []
                checkFailed = wizardNavigateHelper(fieldsToCheck, listFieldsToCheck, booleanFieldsToCheck, teamObjectsToCheck)
            } else if (detail?.requestedStepIndex === 2) {
                const fieldsToCheck = []
                const listFieldsToCheck = [QSC_APP_SPECIFIC_FIELDS.L7_APPROVER, TEMPLATE_APP.PROCESS_OWNER, TEMPLATE_APP.GRC_CONTROL_OWNER, TEMPLATE_APP.VP, QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS]
                const booleanFieldsToCheck = []
                const teamObjectsToCheck = [TEMPLATE_APP.ACCOUNTING_TEAM]
                checkFailed = wizardNavigateHelper(fieldsToCheck, listFieldsToCheck, booleanFieldsToCheck, teamObjectsToCheck)
            } else if (detail?.requestedStepIndex === 3) {
                const fieldsToCheck = [QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE, QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL, QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL, TEMPLATE_APP.ANNUAL_MER_SAVINGS, QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE]
                const listFieldsToCheck = [QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL]
                const booleanFieldsToCheck = [QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED]
                const teamObjectsToCheck = []

                if ((renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE] === 'FDV')) {
                    fieldsToCheck.push(QSC_APP_SPECIFIC_FIELDS.FDV_DATASET_URL)
                    fieldsToCheck.push(QSC_APP_SPECIFIC_FIELDS.FDV_QUERY_NAME)
                }

                checkFailed = wizardNavigateHelper(fieldsToCheck, listFieldsToCheck, booleanFieldsToCheck, teamObjectsToCheck)
            } else if (detail?.requestedStepIndex === 4) {
                const fieldsToCheck = []
                const listFieldsToCheck = []
                const booleanFieldsToCheck = [QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING, QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD, QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC]
                const teamObjectsToCheck = []

                if ((renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD) &&
                    renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD] === true)
                    && (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING) &&
                        renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING] === true)
                    && (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC) &&
                        renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC] === true)) {
                    fieldsToCheck.push(QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET)
                }

                checkFailed = wizardNavigateHelper(fieldsToCheck, listFieldsToCheck, booleanFieldsToCheck, teamObjectsToCheck)
            }

        }
        if (checkFailed === false) {
            setErrorText()
            setActiveStepIndex(detail.requestedStepIndex)
        }
    }

    const wizardNavigateHelper = (fieldsToCheck, listFieldsToCheck, booleanFieldsToCheck, teamObjectsToCheck) => {

        let checkFailed = false

        fieldsToCheck.forEach((field) => {
            if (!(renderJsonEdited.hasOwnProperty(field) && renderJsonEdited[field] && renderJsonEdited[field].trim() !== '')) {
                setErrorText('Please provide all required fields!')
                checkFailed = true
            }
        })
        listFieldsToCheck.forEach((field) => {
            if (!(renderJsonEdited.hasOwnProperty(field) && renderJsonEdited[field] && renderJsonEdited[field]?.length >= 1)) {
                setErrorText('Please provide all required fields!')
                checkFailed = true
            }
        })
        booleanFieldsToCheck.forEach((field) => {
            if (!(renderJsonEdited.hasOwnProperty(field) && (renderJsonEdited[field] === true || renderJsonEdited[field] === false))) {
                setErrorText('Please provide all required fields!')
                checkFailed = true
            }
        })

        teamObjectsToCheck.forEach((field) => {
            if (!(renderJsonEdited.hasOwnProperty(field) && renderJsonEdited[field].hasOwnProperty('team')
                && renderJsonEdited[field].hasOwnProperty('email')
                && renderJsonEdited[field]['team']
                && renderJsonEdited[field]['email']
                && renderJsonEdited[field]['team'].trim() !== ''
                && renderJsonEdited[field]['email'].trim() !== '')) {
                setErrorText('Please provide all required fields!')
                checkFailed = true
            }
        })

        return checkFailed;

    }

    const onQSCAppSubmit = () => {
        let checkFailed = false
        const fieldsToCheck = [TEMPLATE_APP.APP_NAME, TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.STATUS, TEMPLATE_APP.FINANCIAL_YEAR_IMPACT,
            QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE, QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL, 
            QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL, TEMPLATE_APP.ANNUAL_MER_SAVINGS, QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE
        ]
        const listFieldsToCheck = [TEMPLATE_APP.FINANCIAL_RELEVANCE, TEMPLATE_APP.LEDGER_INFO, TEMPLATE_APP.CADENCE,
            QSC_APP_SPECIFIC_FIELDS.L7_APPROVER, TEMPLATE_APP.PROCESS_OWNER, TEMPLATE_APP.GRC_CONTROL_OWNER, TEMPLATE_APP.VP,
            QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL, QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS,TEMPLATE_APP.ORGANIZATION
        ]
        const booleanFieldsToCheck = [QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED, 
            QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING, QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD, 
            QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC
        ]
        const teamObjectsToCheck = [TEMPLATE_APP.ACCOUNTING_TEAM]

        if ((renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE] === 'FDV')) {
            fieldsToCheck.push(QSC_APP_SPECIFIC_FIELDS.FDV_DATASET_URL)
            fieldsToCheck.push(QSC_APP_SPECIFIC_FIELDS.FDV_QUERY_NAME)
        }

        if ((renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD) &&
                    renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD] === true)
                    && (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING) &&
                        renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING] === true)
                    && (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC) &&
                        renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC] === true)) {
                    fieldsToCheck.push(QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET)
        }

        checkFailed = wizardNavigateHelper(fieldsToCheck, listFieldsToCheck, booleanFieldsToCheck, teamObjectsToCheck)

        if (checkFailed === false) {
            setErrorText()
            setShowLoadingIcon(true)
            
            // TODO
            renderJsonEdited[TEMPLATE_APP.LAST_UPDATED_BY] = userId
            renderJsonEdited[TEMPLATE_APP.CREATED_BY] = userId
            renderJsonEdited[TEMPLATE_APP.TEMPLATE_ID] = TEMPLATES.QSC
            renderJsonEdited[TEMPLATE_APP.USE_CASE] = ['Dashboard vizualization']
            renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.MIGRATION_STATUS] = 'Under Review'
            let output = {}
            output[TEMPLATE_APP_OUTPUT.TYPE] = 'Quicksight dashboard'
            output[TEMPLATE_APP_OUTPUT.VALUE] = ''
            output[TEMPLATE_APP_OUTPUT.LABEL] = ''
            renderJsonEdited[TEMPLATE_APP.OUTPUT] = [output]


            createAuditLog(`APP`, `ADD_REQ#${getCurrentTime()}`, "", JSON.stringify({}), JSON.stringify(renderJsonEdited), "ADD REQ", userId)
        addQSCApp(renderJsonEdited, function onSuccess(response) {
            setShowLoadingIcon(false)
            history.push("/App/" + response.data.addQSCApp.app_id)
            
        }, function onFailure(error) {
            setErrorText(error?.errors[0]?.message)
            setShowLoadingIcon(false)
        })
        }

    }

    const wizardContent = (
        <>
        {typeof(qscAddAppAccess) === 'undefined' ?
        <LoadingSpinner></LoadingSpinner> :
        (qscAddAppAccess === false) ? <><ErrorComponent header="Access Denied" message={`User is not authorized to add a new QSC App, please reach out to ATEAM-BI if you require access.`}/></> :
        (qscAddAppAccess === true) ? <Wizard
        i18nStrings={{
            stepNumberLabel: stepNumber =>
                `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) =>
                `Step ${stepNumber} of ${stepsCount}`,
            skipToButtonLabel: (step, stepNumber) =>
                `Skip to ${step.title}`,
            // navigationAriaLabel: "Steps",
            cancelButton: "Cancel",
            previousButton: "Previous",
            nextButton: "Next",
            submitButton: "Add QSC App",
            optional: "optional"
        }}
        onNavigate={({ detail }) =>
            onWizardNavigate(detail)
        }
        onCancel={() => {
            history.push("/")
        }}
        onSubmit={() => {
            onQSCAppSubmit()
        }}
        activeStepIndex={activeStepIndex}
        allowSkipTo={false}
        steps={[
            {
                title: "Provide Dashboard Details",
                info: <Link variant="info">Info</Link>,
                errorText: errorText,
                content: (
                    <Container
                        header={
                            <Header variant="h2">
                                Dashboard Details
                            </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="App Name" stretch>
                                <CustomInput field={TEMPLATE_APP.APP_NAME} placeholder="App name" updateValue={updateValue} getValue={getValue} required={true} ></CustomInput>
                            </FormField>
                            <FormField label="Description" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <div> <Textarea
                                        class="description"
                                        onChange={({ detail }) => updateValue(TEMPLATE_APP.APP_DESCRIPTION, detail.value)}
                                        value={getValue(TEMPLATE_APP.APP_DESCRIPTION)}
                                        placeholder="Description"
                                    /> </div>
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>

                            </FormField>
                            <FormField label="Financial Year Impact" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomSelect field={TEMPLATE_APP.FINANCIAL_YEAR_IMPACT} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Ledger Impact" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomMultiSelect field={TEMPLATE_APP.LEDGER_INFO}
                                        updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Financial Relavance" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomMultiSelect field={TEMPLATE_APP.FINANCIAL_RELEVANCE} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Run Frequency" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomMultiSelect field={TEMPLATE_APP.CADENCE}
                                        updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Organization(s) Supported" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomMultiSelect field={TEMPLATE_APP.ORGANIZATION}
                                        updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>

                            <FormField label="Permission(s)" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                          <CustomMultiHybridInputsWithPK field={TEMPLATE_APP.PERMISSION} subKeys={[{ subKey: TEMPLATE_APP_PERMISSION.NAME, placeholder: 'Name/Description' }, { subKey: TEMPLATE_APP_PERMISSION.LINK, placeholder: 'URL/Link' }]}
                            pk={TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK} updateValue={updateValue} getValues={getValue} />
                            <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                          
                    

                        </SpaceBetween>
                    </Container>
                ),
                isOptional: false
            },
            {
                title: "Provide Team Details",
                errorText: errorText,
                content: (
                    <Container
                        header={
                            <Header variant="h2">
                                Team Details
                            </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="L7 Approver" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomSelectAlias field={QSC_APP_SPECIFIC_FIELDS.L7_APPROVER} getValue={getValue} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} aliasesLoading={aliasesLoading}/>
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="GRC Control Owner" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomSelectAlias field={TEMPLATE_APP.GRC_CONTROL_OWNER} getValue={getValue} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} aliasesLoading={aliasesLoading}/>
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Process Owner" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomSelectMultiAlias field={TEMPLATE_APP.PROCESS_OWNER} getValues={getMultiInputFieldValues} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} aliasesLoading={aliasesLoading}/>
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Accounting VP" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomSelectMultiAlias field={TEMPLATE_APP.VP} getValues={getMultiInputFieldValues} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} aliasesLoading={aliasesLoading} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Developer Alias" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomSelectAlias field={QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS} getValue={getValue} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} aliasesLoading={aliasesLoading}/>
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Accounting Team" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <SpaceBetween direction="vertical">

                                        <CustomSelect field={TEMPLATE_APP.ACCOUNTING_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.TEAM} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                                        <CustomInput field={TEMPLATE_APP.ACCOUNTING_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.EMAIL}
                                            placeholder="email" updateValue={updateValue} getValue={getValue} postProcess={postProcessTeamEmails}
                                            info='For emails, type the alias only (without @amazon.com)'
                                        ></CustomInput>
                                    </SpaceBetween>
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>

                            </FormField>
                        </SpaceBetween>
                    </Container>
                ),
                isOptional: false
            },
            {
                title: "Other Details",
                errorText: errorText,
                content: (
                    <Container
                        header={
                            <Header variant="h2">
                                Other Details
                            </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Data Source" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomSelect field={QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            {renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE] === 'FDV' && (
                                <>
                                    <FormField label="FDV Query Name" stretch>
                                        <Grid
                                            gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                        >

                                            <CustomInput field={QSC_APP_SPECIFIC_FIELDS.FDV_QUERY_NAME} placeholder="FDV query name" updateValue={updateValue} getValue={getValue} ></CustomInput>
                                            <div> <span className="mandatory">*</span> </div>
                                        </Grid>
                                    </FormField>
                                    <FormField label="FDV Dataset URL(s) (on Data Discovery)" stretch>
                                        <Grid
                                            gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                        >
                                            <CustomInput field={QSC_APP_SPECIFIC_FIELDS.FDV_DATASET_URL} placeholder="fgbsbi-gamma analysis URL" updateValue={updateValue} getValue={getValue}></CustomInput>
                                            <div> <span className="mandatory">*</span> </div>
                                        </Grid>
                                    </FormField>
                                </>
                            )}

                            <FormField label="fgbsbi-gamma dataset URL(s)" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomMultiInput field={QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL} updateValue={updateValue}
                                        getValues={getMultiInputFieldValues} ></CustomMultiInput>
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>

                            <FormField label="fgbsbi-gamma analysis URL" stretch>

                                <CustomInput field={QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL} placeholder="fgbsbi-gamma analysis URL" updateValue={updateValue} getValue={getValue} required={true} ></CustomInput>

                            </FormField>

                            <FormField label="fgbsbi-gamma dashboard URL" stretch>

                                <CustomInput field={QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL} placeholder="fgbsbi-gamma dashboard URL" updateValue={updateValue} getValue={getValue} required={true} ></CustomInput>

                            </FormField>

                            <FormField label="MER Savings" stretch>

                                <CustomInput field={TEMPLATE_APP.ANNUAL_MER_SAVINGS} placeholder="Annual MER savings" updateValue={updateValue} getValue={getValue} required={true}></CustomInput>

                            </FormField>

                            <FormField label="Other Benefit" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomInput field={QSC_APP_SPECIFIC_FIELDS.OTHER_BENEFIT} placeholder="Other Benefit" updateValue={updateValue} getValue={getValue}></CustomInput>
                                    <div></div>
                                </Grid>
                            </FormField>
                            <FormField label="Downstream Use" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomSelect field={QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>

                            <FormField label={`Confirmation that you have shared the Gamma analysis and dashboard to “ateam-bi“ as co-owner?`} stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomRadioGroup field={QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED} getValue={getValue} updateValue={updateValue} getOptions={getRadioYesOrNoOptions} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>


                        </SpaceBetween>
                    </Container>
                ),
                isOptional: false
            },
            {
                title: "SOX Questionnaire",
                errorText: errorText,
                content: (
                    <Container
                        header={
                            <Header variant="h2">
                                Please answer below
                            </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="If MJE posting, does it affect Primary Ledger or if Secondary Ledger IN or JP or UK or DE?" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomRadioGroup field={QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING} getValue={getValue} updateValue={updateValue} getOptions={getRadioYesOrNoOptions} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Please confirm if the financial impact of this dashboard is greater than 500 million dollars per year?" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomRadioGroup field={QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD} getValue={getValue} updateValue={updateValue} getOptions={getRadioYesOrNoOptions} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>
                            <FormField label="Does this result in a journal entry or account reconciliation or FS disclosure or key control?" stretch>
                                <Grid
                                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                                >
                                    <CustomRadioGroup field={QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC} getValue={getValue} updateValue={updateValue} getOptions={getRadioYesOrNoOptions} />
                                    <div> <span className="mandatory">*</span> </div>
                                </Grid>
                            </FormField>






                            {((renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD) &&
                                renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD] === true)
                                && (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING) &&
                                    renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING] === true)
                                && (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC) &&
                                    renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC] === true))
                                &&
                                (
                                    <FormField label="GRC Sim Ticket" stretch>
                                        <CustomInput field={QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET} placeholder="GRC Sim Ticket" updateValue={updateValue} getValue={getValue} required={true} ></CustomInput>
                                    </FormField>
                                )}

                        </SpaceBetween>
                    </Container>
                ),
                isOptional: false
            },
            {
                title: "Review and Add",
                errorText: errorText,
                content: (
                    showLoadingIcon ? <>
                    <LoadingSpinner></LoadingSpinner>
                    </> : (
                    <SpaceBetween size="xs">
                        <Header
                            variant="h3"
                            actions={
                                <Button
                                    onClick={() => setActiveStepIndex(0)}
                                >
                                    Edit
                                </Button>
                            }
                        >
                            Details to review
                        </Header>
                        <Container
                            header={
                                <Header variant="h2">
                                    All Details
                                </Header>
                            }
                        >
                            <KeyValuePairs
                                columns={3}
                                items={[
                                    {
                                        label: "App Name",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.APP_NAME) && renderJsonEdited[TEMPLATE_APP.APP_NAME])  ? renderJsonEdited[TEMPLATE_APP.APP_NAME] : '-'
                                    },
                                    {
                                        label: "Description",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.APP_DESCRIPTION) && renderJsonEdited[TEMPLATE_APP.APP_DESCRIPTION])  ? renderJsonEdited[TEMPLATE_APP.APP_DESCRIPTION] : '-'
                                    },
                                    {
                                        label: "Status",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.STATUS) && renderJsonEdited[TEMPLATE_APP.STATUS])  ? renderJsonEdited[TEMPLATE_APP.STATUS] : '-'
                                    },
                                    {
                                        label: "Financial Year Impact",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.FINANCIAL_YEAR_IMPACT) && renderJsonEdited[TEMPLATE_APP.FINANCIAL_YEAR_IMPACT])  ? renderJsonEdited[TEMPLATE_APP.FINANCIAL_YEAR_IMPACT] : '-'
                                    },
                                    {
                                        label: "Ledger Impact",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.LEDGER_INFO) && renderJsonEdited[TEMPLATE_APP.LEDGER_INFO])  ? renderJsonEdited[TEMPLATE_APP.LEDGER_INFO].join(', ') : '-'
                                    },
                                    {
                                        label: "Financial Relavence",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.FINANCIAL_RELEVANCE) && renderJsonEdited[TEMPLATE_APP.FINANCIAL_RELEVANCE])  ? renderJsonEdited[TEMPLATE_APP.FINANCIAL_RELEVANCE].join(', ') : '-'
                                    },
                                    {
                                        label: "Run Frequency",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.CADENCE) && renderJsonEdited[TEMPLATE_APP.CADENCE])  ? renderJsonEdited[TEMPLATE_APP.CADENCE].join(', ') : '-'
                                    },
                                    {
                                        label: "Organization(s) Supported",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.ORGANIZATION) && renderJsonEdited[TEMPLATE_APP.ORGANIZATION])  ? renderJsonEdited[TEMPLATE_APP.ORGANIZATION].join(', ') : '-'
                                    },
                                    {
                                        label: "L7 Approver",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.L7_APPROVER) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.L7_APPROVER])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.L7_APPROVER].join(', ') : '-'
                                    },
                                    {
                                        label: "GRC Control Owner",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.GRC_CONTROL_OWNER) && renderJsonEdited[TEMPLATE_APP.GRC_CONTROL_OWNER])  ? renderJsonEdited[TEMPLATE_APP.GRC_CONTROL_OWNER].join(', ') : '-'
                                    },
                                    {
                                        label: "Process Owner(s)",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.PROCESS_OWNER) && renderJsonEdited[TEMPLATE_APP.PROCESS_OWNER])  ? renderJsonEdited[TEMPLATE_APP.PROCESS_OWNER].join(', ') : '-'
                                    },
                                    {
                                        label: "Accounting VP(s)",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.VP) && renderJsonEdited[TEMPLATE_APP.VP])  ? renderJsonEdited[TEMPLATE_APP.VP].join(', ') : '-'
                                    },
                                    {
                                        label: "Developer Alias",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.DEVELOPER_ALIAS].join(', ') : '-'
                                    },
                                    {
                                        label: "Accounting Team",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.ACCOUNTING_TEAM) && renderJsonEdited[TEMPLATE_APP.ACCOUNTING_TEAM])  ? getTeamObjectStringFormat(renderJsonEdited[TEMPLATE_APP.ACCOUNTING_TEAM]) : '-'
                                    },
                                    {
                                        label: "Data Source",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.QSC_DATASOURCE] : '-'
                                    },
                                    {
                                        label: "FDV Query URL",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FDV_QUERY_NAME) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FDV_QUERY_NAME])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FDV_QUERY_NAME] : '-'
                                    },
                                    {
                                        label: "FDV Dataset URL",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FDV_DATASET_URL) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FDV_DATASET_URL])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FDV_DATASET_URL] : '-'
                                    },
                                    {
                                        label: "fgbsbi-gamma dataset URL(s)",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DATASET_URL].join(', ') : '-'
                                    },
                                    {
                                        label: "fgbsbi-gamma analysis URL",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_ANALYSIS_URL] : '-'
                                    },
                                    {
                                        label: "fgbsbi-gamma dashboard URL",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FGBSBI_GAMMA_DASHBOARD_URL] : '-'
                                    },
                                    {
                                        label: "MER Savings",
                                        value: (renderJsonEdited.hasOwnProperty(TEMPLATE_APP.ANNUAL_MER_SAVINGS) && renderJsonEdited[TEMPLATE_APP.ANNUAL_MER_SAVINGS])  ? renderJsonEdited[TEMPLATE_APP.ANNUAL_MER_SAVINGS] : '-'
                                    },
                                    {
                                        label: "Other Benefit",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.OTHER_BENEFIT) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.OTHER_BENEFIT])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.OTHER_BENEFIT] : '-'
                                    },
                                    {
                                        label: "Downstream Use",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.DOWNSTREAM_USE] : '-'
                                    }
                                ]}
                            />
                            <br></br>
                            <KeyValuePairs
                                columns={1}
                                items={[
                                    {
                                        label: "Confirmation that you have shared the Gamma analysis and dashboard to “ateam-bi“ as co-owner?",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED) && (renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED] === true || renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED] === false))  ? getYesOrNoBasedOnBoolean(renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.GAMMA_ANALYSIS_AND_DASHBOARD_SHARED]) : '-'
                                    },
                                    {
                                        label: "If MJE posting, does it affect Primary Ledger or if Secondary Ledger IN or JP or UK or DE?",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING) && (renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING] === true || renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING] === false))  ? getYesOrNoBasedOnBoolean(renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.PRIMARY_OR_SECONDARY_LEDGER_IMPACTING]) : '-'
                                    },
                                    {
                                        label: "Please confirm if the financial impact of this dashboard is greater than 500 million dollars per year?",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD) && (renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD] === true || renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD] === false))  ? getYesOrNoBasedOnBoolean(renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.FINANCIAL_IMPACT_GREATER_THAN_500M_USD]) : '-'
                                    },
                                    {
                                        label: "Does this result in a journal entry or account reconciliation or FS disclosure or key control?",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC) && (renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC] === true || renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC] === false))  ? getYesOrNoBasedOnBoolean(renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.RESULT_IN_JE_ACC_RECON_FSD_KC]) : '-'
                                    },
                                    {
                                        label: "GRC Sim Ticket",
                                        value: (renderJsonEdited.hasOwnProperty(QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET) && renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET])  ? renderJsonEdited[QSC_APP_SPECIFIC_FIELDS.GRC_SIM_TICKET] : '-'
                                    }
                                ]}
                            />
                        </Container>
                    </SpaceBetween>
                )
                )
            }
        ]}
    /> : <> </>

        }
               </>
    )
    return (
        <AppLayout
            disableContentPaddings={false}
            navigation={<SideNav activeHref={`/QSCApp`} customSideNavitems={customSideNavitems} />}
            navigationOpen={NavOpen}
            content={wizardContent}
            headerSelector="#TopBar"
            toolsHide={true}
            onNavigationChange={() => {
                setNavOpen(!NavOpen);
            }}
            headerVariant="high-contrast"
            contentType="wizard"
        />

    );
}