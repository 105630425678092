import React, {useState} from 'react';

import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";

export default (props) => {
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const history = useHistory();

  const items = [
    
    {
        "type": "link",
        "text": "Back",
        "href": "/"
    },


    ];
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: "/Admin", text: "Admin panel" }}
      onFollow={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
    }}
      items={items}
    />
  );
}